import Axios from "axios";
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import PatientContext from "../../context/patient.context";
import Alert from "../../util/alert";
import domain from "../../util/domain";
//import moment from "moment";
//import WaitingButton from "../common/waitingbutton.component";
import DatePicker, { registerLocale } from "react-datepicker";
import id from "date-fns/locale/id";
import Loading from "../common/loading.component";

registerLocale("id", id);

export default function Login(props) {
    //const [mrn, setMRN] = useState("109233");
    //const [dateOfBirth, setDateOfBirth] = useState(new Date("1984-03-31"));
    const [mrn, setMRN] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState();
    const [otp, setOTP] = useState("");
    const [notification, setNotification] = useState();
    const [isPendingOTPValidation, setIsPendingValidation] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);

    //const { patient, getPatient } = useContext(PatientContext);
    const { getPatient } = useContext(PatientContext);
    const history = useHistory();

    var minBirthDate = new Date();
    var today = new Date();
    minBirthDate.setFullYear(minBirthDate.getFullYear() - 100);

    async function login(e) {
        e.preventDefault();
        const loginData = {
            mrn: mrn,
            dateOfBirth: dateOfBirth
        };

        setIsWaiting(true);
        try {
            await Axios.post(`${domain}/patients/login`, loginData)
                .then((response) => {
                    setNotification({
                        message: "OTP telah dikirimkan ke *******" + response.data.handphone.slice(7),
                        type: "success"
                    });
                    setIsPendingValidation(true);
                })
                .catch((error) => {
                    setOTP("");
                    if (!error.response) {
                        // network error
                        setNotification({
                            message: "Exception: " + error,
                            type: "danger"
                        });
                    } else {
                        //setError(error.response.data.msg);
                        setNotification({
                            message: error.response.data.msg,
                            type: "danger"
                        });
                    }
                });
        } catch (err) {
            console.log(err);
            // ...
        } finally {
            setIsWaiting(false);
        }
    }

    async function validateOTP(e) {
        e.preventDefault();
        const data = {
            otp: otp,
            mrn: mrn,
            dateOfBirth: dateOfBirth
        };

        setIsWaiting(true);
        try {
            await Axios.post(`${domain}/patients/validateotp`, data);
        } catch (error) {
            if (!error.response) {
                // network error
                setNotification({
                    message: "Exception: " + error,
                    type: "danger"
                });
            } else {
                //setError(error.response.data.msg);
                setNotification({
                    message: error.response.data.msg,
                    type: "danger"
                });
            }
            return;
        } finally {
            setIsWaiting(false);
        }

        await getPatient();
        history.push("/");
    }

    async function login1(e) {
        e.preventDefault();
        const data = {
            otp: otp,
            mrn: mrn,
            dateOfBirth: dateOfBirth
        };

        try {
            await Axios.post(`${domain}/patients/login1`, data);
        } catch (error) {
            if (!error.response) {
                // network error
                setNotification({
                    message: "Exception: " + error,
                    type: "danger"
                });
            } else {
                //setError(error.response.data.msg);
                setNotification({
                    message: error.response.data.msg,
                    type: "danger"
                });
            }
            return;
        }

        await getPatient();

        if (props.location.state && props.location.state.from) {
            history.push(props.location.state.from);
        } else {
            history.push("/register");
        }
    }

    const onChangeMRN = (newValue) => {
        setMRN(newValue.replace(/\D/g, ""));
    };

    return (
        <div style={{ maxWidth: "400px" }} className="mx-auto">
            <img src="/img/LogoRSCS-Black.png" alt="" height="75" style={{ imageRendering: "-webkit-optimize-contrast" }} className="rounded mx-auto d-block mt-5" />
            <h3 className="text-center">Pendaftaran Online</h3>
            <h4 className="text-center">Login</h4>
            <style type="text/css">{"#navbar {display: none !important}"}</style>
            <style type="text/css">{"#sidebar-container {display: none !important}"}</style>

            {!isPendingOTPValidation ? (
                <form onSubmit={login} autoComplete="on" className="form-signin">
                    <div className="mb-3">
                        <label htmlFor="form-mrn" className="form-label">
                            Nomor Rekam Medis (MRN) / Nomor Handphone
                        </label>
                        <input id="form-mrn" autoFocus required value={mrn} onChange={(e) => onChangeMRN(e.target.value)} className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="form-date" className="form-label">
                            Tanggal Lahir
                        </label>
                        <DatePicker
                            className="form-control"
                            selected={dateOfBirth}
                            onChange={(date) => setDateOfBirth(new Date(date))}
                            locale="id"
                            dateFormat="dd-MM-yyyy"
                            minDate={minBirthDate}
                            maxDate={today}
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        {isWaiting ? (
                            <Loading />
                        ) : (
                            <button type="submit" className="btn btn-primary">
                                Login
                            </button>
                        )}
                    </div>
                </form>
            ) : (
                <form onSubmit={validateOTP} autoComplete="off" className="form-signin">
                    <div className="mb-3">
                        <label htmlFor="form-otp" className="form-label">
                            Kode OTP
                        </label>
                        <input id="form-otp" autoFocus required value={otp} onChange={(e) => setOTP(e.target.value)} className="form-control" />
                    </div>
                    <div className="mb-3 btn-group">
                        {isWaiting ? (
                            <Loading />
                        ) : (
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        )}
                    </div>
                </form>
            )}
            {notification && <Alert message={notification.message} type={notification.type} />}
        </div>
    );
}
