import moment from "moment";
import { useEffect, useState } from "react";

export default function CountDownTimer({ expiryDate }) {
    const calculateTimeLeft = () => {
        let eventTime = Math.floor(new Date(expiryDate.getTime() + 15 * 60000) / 1000).toString();
        let currentTime = Math.floor(Date.now() / 1000).toString();
        let leftTime = eventTime - currentTime;

        let duration = moment.duration(leftTime, "seconds");
        let interval = 1000;
        if (duration.asSeconds() <= 0) {
            clearInterval(interval);
        }
        duration = moment.duration(duration.asSeconds() - 1, "seconds");
        
        let seconds = duration.seconds();
        if (seconds >= 0) {
            return "Berakhir dalam " + duration.minutes() + " menit " + duration.seconds() + " detik";
        } else {
            return "Batas waktu bayar terlampaui. Mohon melakukan pembayaran di lobi pendaftaran";
        }
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    return <span>{timeLeft}</span>;
}
