import React from "react";

const Alert = ({ message, type }) => {
    return (
        <div className={"alert alert-" + type + " alert-dismissible"} role="alert">
            <div>{message}</div>
        </div>
    );
};

export default Alert;
