import "./App.css";
import { PatientContextProvider } from "./context/patient.context";
import Router from "./Router";
import React from "react";
import axios from "axios";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "react-datepicker/dist/react-datepicker.css";

axios.defaults.withCredentials = true;

function App() {
    return (
        <PatientContextProvider>
            <Router />
        </PatientContextProvider>
    );
}

export default App;
