import axios from "axios";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import PatientContext from "../../context/patient.context";
import domain from "../../util/domain";

export default function Navbar() {
    const { getPatient } = useContext(PatientContext);
    const history = useHistory();

    async function logOut() {
        await axios.get(`${domain}/patients/logOut`);
        await getPatient();
        history.push("/login");
    }

    return (
        <nav id="navbar" className="navbar navbar-dark bg-dark navbar-expand-lg">
            <div className="row container-fluid">
                <div className="col">
                    <a className="navbar-brand" href="/#">
                        <img src="/img/LogoRSCS.png" alt="" height="45" style={{ filter: "blur(0)", transform: "translateZ(0)" }} />
                    </a>
                </div>
                <div className="col text-right">
                    <button className="btn text-white" onClick={logOut}>
                        Log out
                    </button>
                </div>
            </div>
        </nav>
    );
}
