// Autocomplete.js

import React, { useState } from "react";

const AutocompleteFlex = (props) => {
    const items = props.items; // This could be a GET request to whatever

    const [state, setState] = useState({
        activeItem: 0,
        filteredItems: [],
        displayItems: false,
        inputValue: ""
    });

    const handleChange = (e) => {
        const inputValue = e.currentTarget.value;
        const filteredItems = items.filter((optionName) => optionName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1).sort((a, b) => a > b);

        setState({
            activeItem: 0,
            filteredItems,
            displayItems: true,
            inputValue: e.currentTarget.value
        });

        props.handleChange(e.currentTarget.value);
    };

    const handleClick = (e) => {
        setState({
            activeItem: 0,
            filteredItems: [],
            displayItems: false,
            inputValue: e.currentTarget.innerText
        });
        props.handleClick(e.currentTarget.innerText, e.currentTarget.getAttribute("index"));
    };

    const handleKeyDown = (e) => {
        const { activeItem, filteredItems } = state;

        if (e.keyCode === 13) {
            setState({
                activeItem: 0,
                filteredItems: [],
                displayItems: false,
                inputValue: filteredItems[activeItem]
            });
        } else if (e.keyCode === 38) {
            e.preventDefault();
            if (activeItem === 0) {
                return;
            }
            setState({
                activeItem: activeItem - 1,
                filteredItems,
                displayItems: true,
                inputValue: e.currentTarget.value
            });
        } else if (e.keyCode === 40) {
            e.preventDefault();
            if ((filteredItems && activeItem === filteredItems.length - 1) || activeItem >= 9) {
                return;
            }
            setState({
                activeItem: activeItem + 1,
                filteredItems,
                displayItems: true,
                inputValue: e.currentTarget.value
            });
        }
    };

    return (
        <>
            <div className="uk-inline uk-width-1-1 uk-margin-top mb-1">
                <style type="text/css">{`li:hover { background-color: grey; }`}</style>
                <span className="uk-form-icon" data-uk-icon="icon: world" />
                <input
                    className="form-control"
                    name="languages"
                    label="Items"
                    placeholder={props.placeholder}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    value={state.inputValue || props.defaultValue}
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="off"
                    required
                />

                {state.displayItems && state.inputValue.length && state.filteredItems ? (
                    <div className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                        <ul className="uk-list">
                            {state.filteredItems
                                .map((optionName, index) => {
                                    return (
                                        <li key={optionName} index={index} onClick={handleClick}>
                                            {optionName}
                                        </li>
                                    );
                                })
                                .slice(0, 20)}
                        </ul>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default AutocompleteFlex;
