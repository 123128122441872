import React from "react";

export default function HowToPay({ paymentMethod, accountNumber }) {
    return (
        <>
            {paymentMethod === "BRI" && (
                <>
                    <h6><b>BRI Mobile/ATM BRI</b></h6>
                    <ol>
                        <li>Masuk ke menu pembayaran</li>
                        <li>Pilih BRIVA / Virtual Account</li>
                        <li>Masukkan Nomor Virtual Account {accountNumber}</li>
                    </ol>
                </>
            )}
            {paymentMethod === "BRI" && (
                <>
                    <h6><b>BNI Mobile/ATM BRI</b></h6>
                    <ol>
                        <li>Masuk ke menu transfer</li>
                        <li>Virtual Account Billing</li>
                        <li>Masukkan Nomor Virtual Account {accountNumber}</li>
                    </ol>
                </>
            )}
            {paymentMethod === "MANDIRI" && (
                <>
                    <h6><b>Livin/ATM Mandiri</b></h6>
                    <ol>
                        <li>Masuk ke menu Bayar</li>
                        <li>Di menu cari penyedia jasa, pilih/ketik Xendit</li>
                        <li>Masukkan Nomor Virtual Account {accountNumber}</li>
                    </ol>
                </>
            )}
            {paymentMethod === "QRIS" && (
                <>
                    <h6><b>QRIS</b></h6>
                    <ol>
                        <li>Screenshot QR yang ditampilkan</li>
                        <li>Di menu aplikasi pembayaran, pilih QRIS dan upload foto screenshot QR</li>
                        <li>Konfirmasi pembayaran, atau</li>
                        <li>Gunakan handphone / perangkat lain untuk scan QR</li>
                    </ol>
                </>
            )}
        </>
    );
}

/*
<div className="d-flex align-items-center">
            <strong>Loading...</strong>
            <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
        </div>
        */
