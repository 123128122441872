import React, { useRef, useState, useEffect, useContext } from "react";
import domain from "../../util/domain";
import Alert from "../../util/alert";
import axios from "axios";
import PatientContext from "../../context/patient.context";
import moment from "moment";
import "moment/locale/id";
import AutocompleteFlex from "../common/AutocompleteFlex";
//import WaitingButton from "../common/waitingbutton.component";
import DatePicker, { registerLocale } from "react-datepicker";
import id from "date-fns/locale/id";
import Loading from "../common/loading.component";
import { QRCode } from "react-qrcode-logo";
import HowToPay from "../common/howtopay.component";
import CountDownTimer from "../common/countdowntimer.component";
import getAgeInYears from "../common/getAgeInYears";
registerLocale("id", id);

export default function Main() {
    const { patient } = useContext(PatientContext);
    const [notification, setNotification] = useState();
    const [previewMode, setPreviewMode] = useState(false);
    const [loadedPatient, setLoadedPatient] = useState();
    const [paymentMethod, setPaymentMethod] = useState("");
    const [showHowToPay, setShowHowToPay] = useState(false);
    const inputFile = useRef(null);
    const [file, setFile] = useState();
    const platformFee = 0;

    const defaultDoctor = {
        name: "Pilih Dokter",
        schedule: [],
        schedule_date: []
    };
    const [selectedDoctor, setSelectedDoctor] = useState(defaultDoctor);
    const [selectedRoom, setSelectedRoom] = useState("Anak");
    const [selectedTime, setSelectedTime] = useState();

    var today = new Date();
    today.setHours(0, 0, 0, 0);
    const [selectedDate, setSelectedDate] = useState(today);

    var maxDay = new Date();
    maxDay.setDate(maxDay.getDate() + 1);

    const [doctors, setDoctors] = useState([]);
    const [registrations, setRegistrations] = useState([]);
    const [allRegistrations, setAllRegistrations] = useState([]);
    const [guarantor, setGuarantor] = useState("Umum");
    const [company, setCompany] = useState();
    const [insuranceID, setInsuranceID] = useState();
    const [companies, setCompanies] = useState([]);

    async function fetchDoctors() {
        await axios
            .get(`${domain}/doctors/`)
            .then((response) => {
                setDoctors(response.data);
            })
            .catch((error) => {
                if (!error.response) {
                    // network error
                    setNotification({
                        message: "Exception: " + error,
                        type: "danger"
                    });
                } else {
                    //setError(error.response.data.msg);
                    setNotification({
                        message: error.response.data.msg,
                        type: "danger"
                    });
                }
            });
    }

    async function fetchCompanies() {
        await axios
            .get(`${domain}/companies/`)
            .then((response) => {
                var comps = [];
                var arr2 = response.data;
                for (var j in arr2) {
                    comps.push(arr2[j].name);
                }
                setCompanies(comps);
            })
            .catch((error) => {
                if (!error.response) {
                    // network error
                    setNotification({
                        message: "Exception: " + error,
                        type: "danger"
                    });
                } else {
                    //setError(error.response.data.msg);
                    setNotification({
                        message: error.response.data.msg,
                        type: "danger"
                    });
                }
            });
    }

    useEffect(() => {
        async function fetchRegistrations() {
            var date = new Date();
            date.setHours(0, 0, 0, 0);

            await axios
                .post(`${domain}/registrations/` + patient.mrn, { today: date })
                .then((response) => {
                    setRegistrations(response.data.registrations);
                    setAllRegistrations(response.data.allRegistrations);
                    setLoadedPatient(response.data.patient);
                    if (response.data.patient.company) {
                        setGuarantor("Asuransi");
                        setCompany(response.data.patient.company);
                        if (response.data.patient.insuranceID) setInsuranceID(response.data.patient.insuranceID);
                    }
                })
                .catch((error) => {
                    if (!error.response) {
                        // network error
                        setNotification({
                            message: "Exception: " + error,
                            type: "danger"
                        });
                    } else {
                        //setError(error.response.data.msg);
                        setNotification({
                            message: error.response.data.msg,
                            type: "danger"
                        });
                    }
                });
        }
        fetchDoctors();
        fetchRegistrations();
        fetchCompanies();
    }, [patient]);

    const [isWaiting, setIsWaiting] = useState(false);

    const paymentMethods = [
        {
            name: "BNI",
            description: "Virtual Account BNI",
            accountNumberPrefix: "799780",
            xenditPrefix: "8930"
        },
        {
            name: "BRI",
            description: "Virtual Account BRI",
            accountNumberPrefix: "799780",
            xenditPrefix: "13282"
        },
        {
            name: "MANDIRI",
            description: "Virtual Account Mandiri",
            accountNumberPrefix: "79970000",
            xenditPrefix: "88908"
        },
        {
            name: "QRIS",
            description: "QRIS",
            accountNumberPrefix: "",
            xenditPrefix: ""
        },
        {
            name: "MANUAL",
            description: "Manual di Pendaftaran RS",
            accountNumberPrefix: "",
            xenditPrefix: ""
        }
    ];

    async function fetchRegistrations() {
        var date = new Date();
        date.setHours(0, 0, 0, 0);

        await axios
            .post(`${domain}/registrations/` + patient.mrn, { today: date })
            .then((response) => {
                setRegistrations(response.data.registrations);
                setAllRegistrations(response.data.allRegistrations);
            })
            .catch((error) => {
                if (!error.response) {
                    // network error
                    setNotification({
                        message: "Exception: " + error,
                        type: "danger"
                    });
                } else {
                    //setError(error.response.data.msg);
                    setNotification({
                        message: error.response.data.msg,
                        type: "danger"
                    });
                }
            });
    }

    async function onSubmit(e) {
        e.preventDefault();

        var date = new Date();
        date.setHours(0, 0, 0, 0);
        if (moment(selectedDate).isBefore(date)) {
            setNotification({
                message: "Tanggal yang dipilih telah terlewati",
                type: "danger"
            });
            return;
        }

        var formData = new FormData();
        //const file = inputFile.current.files[0];
        if (file !== undefined && (file.size > 3145728 || (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/jpg"))) {
            setNotification({
                message: "File " + file.name + " berukuran lebih dari 3MB / format bukan png/jpg. Mohon mengecilkan ukuran, bisa dengan menggunakan screenshot",
                type: "danger"
            });
            return;
        }

        if (file !== undefined) {
            formData.append("file", file);
        }

        formData.append("doctor", selectedDoctor.name);
        formData.append("date", selectedDate);
        formData.append("time", selectedTime);
        formData.append("fee", selectedDoctor.fee);
        formData.append("platformFee", platformFee);
        formData.append("paymentMethod", paymentMethod);
        formData.append("room", selectedDoctor.room);
        formData.append("guarantor", guarantor);

        var index = paymentMethods.findIndex((method) => method.name === paymentMethod);
        if (paymentMethod && index > -1) formData.append("accountNumberPrefix", paymentMethods[index].accountNumberPrefix);
        if (guarantor !== "Umum" && company) formData.append("company", company);
        if (insuranceID) formData.append("insuranceID", insuranceID);

        /*
        const data = {
            doctor: selectedDoctor.name,
            date: selectedDate,
            time: selectedTime,
            fee: selectedDoctor.fee,
            room: selectedDoctor.room,
            guarantor: guarantor,
            company: company
        };
*/
        setIsWaiting(true);
        try {
            var startTime = moment(new Date());
            await axios
                .post(`${domain}/registrations/` + patient.mrn + "/add", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then((response) => {
                    // Success
                    var end = moment(new Date());
                    var duration = moment.duration(end.diff(startTime));
                    console.log("duration " + duration.asSeconds());
                    //setMedicines(response.data);
                    if (guarantor === "Umum") {
                        setNotification({
                            message: "Pendaftaran telah berhasil dibuat. \nUntuk konfirmasi, silahkan melakukan pembayaran sesuai metode pembayaran yang dipilih",
                            type: "success"
                        });
                    } else {
                        setNotification({
                            message: "Pendaftaran telah berhasil dibuat. \nMohon menyerahkan kartu asuransi kepada petugas di lobi pendaftaran ketika akan berobat",
                            type: "success"
                        });
                    }
                    setPreviewMode(false);
                    setRegistrations(response.data.registrations);
                    setLoadedPatient(response.data.patient);
                    if (response.data.patient.company) {
                        setGuarantor("Asuransi");
                        setCompany(response.data.patient.company);
                        setInsuranceID(response.data.patient.insuranceID);
                    }
                })
                .catch((error) => {
                    if (!error.response) {
                        setNotification({
                            message: error,
                            type: "danger"
                        });
                    } else {
                        setNotification({
                            message: error.response.data.msg,
                            type: "danger"
                        });
                    }
                });
        } catch (err) {
            console.log(err);
            // ...
        } finally {
            setIsWaiting(false);
        }
    }

    const onChangeRoom = (newValue) => {
        if (newValue !== selectedRoom) {
            var filteredDoctors = doctors.filter((doctor) => doctor.room === newValue);
            if (filteredDoctors.length === 1) setSelectedDoctor(filteredDoctors[0]);
            else setSelectedDoctor(defaultDoctor);
            setSelectedRoom(newValue);
        }
    };

    const onChangeDoctor = (newValue) => {
        if (newValue === defaultDoctor.name) {
            setSelectedDoctor(defaultDoctor);
        } else {
            var doctor = doctors.find((doctor) => doctor.name === newValue);
            setSelectedDoctor(doctor);
            var filterdDay = doctor.schedule_date.find((item) => item.date === moment(selectedDate).format("DD-MM-yyyy"));
            if (!filterdDay) filterdDay = doctor.schedule.find((item) => item.day === moment(selectedDate).format("dddd"));
            if (filterdDay) {
                setSelectedTime(filterdDay.time[0]);
            }
        }
    };

    const onChangeDate = (newDate) => {
        setSelectedDate(new Date(newDate.setHours(0, 0, 0, 0)));
        var filterdDay = selectedDoctor.schedule_date.find((item) => item.date === moment(selectedDate).format("DD-MM-yyyy"));
        if (!filterdDay) filterdDay = selectedDoctor.schedule.find((item) => item.day === moment(newDate).format("dddd"));
        if (filterdDay) {
            setSelectedTime(filterdDay.time[0]);
        }
    };

    async function onDelete({ id }) {
        var result = window.confirm("Klik OK untuk konfirmasi penghapusan pendaftaran");
        if (result) {
            setIsWaiting(true);
            try {
                var startTime = moment(new Date());
                await axios
                    .post(`${domain}/registrations/` + patient.mrn + "/delete/" + id)
                    .then((response) => {
                        // Success
                        var end = moment(new Date());
                        var duration = moment.duration(end.diff(startTime));
                        console.log("duration " + duration.asSeconds());
                        setRegistrations(response.data);
                        setNotification({
                            message: "Pendaftaran berhasil dihapus",
                            type: "success"
                        });
                    })
                    .catch((error) => {
                        if (!error.response) {
                            // network error
                            setNotification({
                                message: "Exception: " + error,
                                type: "danger"
                            });
                        } else {
                            setNotification({
                                message: error.response.data.msg,
                                type: "danger"
                            });
                        }
                    });
            } catch (err) {
                console.log(err);
                // ...
            } finally {
                setIsWaiting(false);
            }
        }
    }

    const onProceed = (e) => {
        e.preventDefault();
        document.getElementById("top").scrollIntoView();
        if (guarantor !== "Umum" && !company) {
            setNotification({
                message: "Mohon isi nama perusahaan",
                type: "danger"
            });
            return;
        }

        if (guarantor !== "Umum" && !inputFile.current.files[0] && !loadedPatient.insuranceCardImgUrl) {
            setNotification({
                message: "Mohon upload kartu asuransi",
                type: "danger"
            });
            return;
        }

        if (getAgeInYears(patient.dateOfBirth, new Date()) >= 17 && selectedDoctor.room === "Anak") {
            setNotification({
                message: "Mohon login dengan rekam medis / tanggal lahir anak untuk mendaftar ke Poli Anak",
                type: "danger"
            });
            return;
        }

        if (inputFile.current) {
            var retFile = inputFile.current.files[0];
            if (retFile !== undefined && (retFile.size > 3072000 || (retFile.type !== "image/png" && retFile.type !== "image/jpeg" && retFile.type !== "image/jpg"))) {
                setNotification({
                    message: "File " + retFile.name + " berukuran lebih dari 2MB / format bukan png/jpg",
                    type: "danger"
                });
                return;
            }

            if (retFile) setFile(retFile);
        }

        var index = registrations.findIndex((registration) => registration.doctor === selectedDoctor.name);
        if (index > -1) {
            setNotification({
                message:
                    "Anda telah membuat pendaftaran dengan " +
                    selectedDoctor.name +
                    " pada " +
                    moment(registrations[index].date).format("dddd, DD-MM-YYYY") +
                    ". " +
                    "Untuk membuat pendafaran di hari / jam yang lain, mohon menghapus pendaftaran sebelumnya",
                type: "danger"
            });
        } else {
            setPreviewMode(true);
            setNotification();
        }
    };

    const onChangeCompany = (newCompany) => {
        setCompany(newCompany);
    };

    const getScheduledTimes = () => {
        if (selectedDoctor && selectedDate) {
            var schedule_date = selectedDoctor.schedule_date.find((item) => item.date === moment(selectedDate).format("DD-MM-yyyy"));
            if (schedule_date) {
                return schedule_date.time;
            }
            var schedule = selectedDoctor.schedule.find((item) => item.day === moment(selectedDate).format("dddd"));
            if (schedule) {
                return schedule.time;
            }
        }
        return [];
    };

    const getXenditPrefix = (registration) => {
        var index = paymentMethods.findIndex((method) => method.name === registration.paymentMethod);
        if (index > -1) return paymentMethods[index].xenditPrefix;
        return "";
    };

    const isSelectedSlotDisabled = (time) => {
        if (selectedDoctor.maxPatientsPerSlot) {
            var distinctMRNs = [
                ...new Set(
                    allRegistrations.filter((reg) => reg.doctor === selectedDoctor.name && new Date(reg.date).getTime() === selectedDate.getTime() && reg.time === time).map((reg) => reg.patient.mrn)
                )
            ];
            if (distinctMRNs.length >= selectedDoctor.maxPatientsPerSlot) return true;
        }
        return false;
    };

    return (
        <div style={{ maxWidth: "800px" }} className="mx-auto">
            <h3 className="text-center mt-3">Pendaftaran Online</h3>
            <div id="top">
                <h4 className="text-center">Poliklinik Rawat Jalan</h4>
            </div>
            {notification && <Alert message={notification.message} type={notification.type} />}

            {registrations.length > 0 && (
                <>
                    <h5>Pendaftaran yang telah dibuat</h5>
                    {registrations.map((registration, index) => (
                        <table className="table table-sm" key={index}>
                            <tbody>
                                <tr>
                                    <th style={{ width: "25%" }}>Pasien</th>
                                    <td>
                                        {registration.patient.mrn} - {registration.patient.fullName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Dokter</th>
                                    <td>{registration.doctor}</td>
                                </tr>
                                <tr>
                                    <th>Tanggal / Jam</th>
                                    <td>
                                        {moment(registration.date).format("dddd, DD-MM-YYYY")}, jam {registration.time}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Penjamin</th>
                                    <td>
                                        {registration.guarantor}
                                        {registration.guarantor !== "Umum" && registration.company ? " - " + registration.company : ""}
                                    </td>
                                </tr>
                                {registration.guarantor === "Umum" && (
                                    <>
                                        <tr>
                                            <th>Metode Pembayaran</th>
                                            <td>{registration.paymentMethod}</td>
                                        </tr>
                                        {registration.paymentMethod !== "QRIS" && (
                                            <tr>
                                                <th>Account Number</th>
                                                <td>
                                                    {getXenditPrefix(registration)}
                                                    {registration.accountNumber}
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th>Status Pembayaran</th>
                                            <td>{registration.isPaid ? "Lunas" : "Belum Lunas"}</td>
                                        </tr>
                                    </>
                                )}
                                {registration.paymentMethod === "QRIS" && !registration.isPaid && registration.qr && (
                                    <tr>
                                        <td colSpan={2} className="text-center">
                                            <QRCode value={registration.qr} size={200} qrStyle="dots" />
                                        </td>
                                    </tr>
                                )}
                                {registration.guarantor === "Umum" && registration.paymentMethod !== "MANUAL" && !registration.isPaid && (
                                    <>
                                        <tr>
                                            <td colSpan={2} className="text-center">
                                                <CountDownTimer expiryDate={new Date(registration.createdAt)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="text-center">
                                                <button className="btn text-primary" onClick={() => setShowHowToPay(!showHowToPay)}>
                                                    {showHowToPay ? "Sembunyikan" : "Lihat"} Cara Bayar
                                                </button>
                                            </td>
                                        </tr>
                                    </>
                                )}
                                {showHowToPay && (
                                    <tr>
                                        <td colSpan={2}>
                                            <HowToPay paymentMethod={registration.paymentMethod} accountNumber={getXenditPrefix(registration) + registration.accountNumber} />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td colSpan={2}>
                                        {isWaiting ? (
                                            <Loading />
                                        ) : (
                                            <div className="btn-group">
                                                <button
                                                    className={"btn btn-outline-danger"}
                                                    onClick={() =>
                                                        onDelete({
                                                            id: registration._id
                                                        })
                                                    }
                                                >
                                                    Hapus
                                                </button>
                                                {!registration.isPaid && registration.paymentMethod !== "MANUAL" && (
                                                    <button className={"btn btn-outline-success"} onClick={() => fetchRegistrations()}>
                                                        Cek Status Pembayaran
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ))}
                </>
            )}
            <h5>Buat Pendaftaran Baru</h5>

            {previewMode ? (
                <form onSubmit={onSubmit} autoComplete="off" className="form-signin">
                    <table className="table mb-3">
                        <tbody>
                            <tr>
                                <th style={{ width: "25%" }}>Pasien</th>
                                <td>
                                    {patient.mrn} - {patient.salutation}
                                    {patient.fullName}
                                </td>
                            </tr>
                            <tr>
                                <th>Dokter</th>
                                <td>{selectedDoctor.name}</td>
                            </tr>
                            <tr>
                                <th>Tanggal / Jam</th>
                                <td>
                                    {moment(selectedDate).format("dddd, DD-MM-YYYY")}, jam {selectedTime}
                                </td>
                            </tr>
                            {guarantor === "Umum" && (
                                <>
                                    <tr>
                                        <th>Biaya Pendaftaran</th>
                                        <td>Rp {selectedDoctor.fee.toLocaleString("id-ID")}</td>
                                    </tr>
                                    {paymentMethod !== "MANUAL" && (
                                        <tr>
                                            <th>Biaya Jasa Aplikasi</th>
                                            <td>Rp {platformFee.toLocaleString("id-ID")}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>Metode Pembayaran</th>
                                        <td>{paymentMethod}</td>
                                    </tr>
                                </>
                            )}
                            <tr>
                                <th>Penjamin</th>
                                <td>
                                    {guarantor}
                                    {guarantor !== "Umum" && company ? " - " + company : ""}
                                </td>
                            </tr>
                            {guarantor !== "Umum" && (
                                <tr>
                                    <th>No Kartu Asuransi / ID Karyawan</th>
                                    <td>{insuranceID}</td>
                                </tr>
                            )}
                            <tr>
                                <td colSpan={2}>
                                    {isWaiting ? (
                                        <Loading />
                                    ) : (
                                        <div className="mb-3 btn-group">
                                            <button type="button" className={"btn btn-outline-danger"} onClick={() => setPreviewMode(false)}>
                                                Cancel
                                            </button>
                                            <button type="submit" className={"btn btn-outline-primary"}>
                                                Konfirmasi
                                            </button>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            ) : (
                <form onSubmit={onProceed} autoComplete="off" className="form-signin">
                    <div className="mb-3">
                        <label htmlFor="form-patient" className="form-label">
                            Pasien
                        </label>
                        <input id="form-patient" readOnly value={patient.mrn + " - " + patient.salutation + patient.fullName} className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="listguarantor">
                            Penjamin
                        </label>
                        <select className="form-control" id="listguarantor" value={guarantor} onChange={(e) => setGuarantor(e.target.value)}>
                            <option value={"Umum"}>Umum</option>
                            <option value={"Asuransi"}>Asuransi</option>
                        </select>
                    </div>
                    {guarantor === "Asuransi" && (
                        <>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="form-company">
                                    Perusahaan
                                </label>
                                <AutocompleteFlex items={companies} handleChange={onChangeCompany} handleClick={onChangeCompany} placeholder={"Nama perusahaan"} defaultValue={company || ""} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="insuranceID">No Kartu Asuransi / ID Karyawan</label>
                                <input className="form-control" id="insuranceID" name="insuranceID" required={true} value={insuranceID} onChange={(e) => setInsuranceID(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image">Upload Kartu Asuransi Baru</label>
                                <input ref={inputFile} type="file" id="image" name="image" required={loadedPatient.insuranceCardImgUrl ? false : true} accept=".png,.jpg,.jpeg" />
                            </div>
                            {loadedPatient && loadedPatient.insuranceCardImgUrl && (
                                <div className="mb-3">
                                    <label htmlFor="uploadedImg">Kartu Asuransi yang terdaftar</label>
                                    <br></br>
                                    <img name="uploadedImg" height={150} alt="Kartu Asuransi" src={loadedPatient.insuranceCardImgUrl} />
                                </div>
                            )}
                        </>
                    )}
                    <div className="mb-3">
                        <label className="form-label" htmlFor="listroom">
                            Poli
                        </label>
                        <select className="form-control" id="listroom" value={selectedRoom} onChange={(e) => onChangeRoom(e.target.value)}>
                            {[...new Set(doctors.map((doctor) => doctor.room))]
                                .sort(function (a, b) {
                                    return a.localeCompare(b);
                                })
                                .map((item, index) => (
                                    <option key={index + 1} value={item}>
                                        {item}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="listdoctor">
                            Dokter
                        </label>
                        <select className="form-control" id="listdoctor" value={selectedDoctor.name} onChange={(e) => onChangeDoctor(e.target.value)}>
                            <option key={0} value={defaultDoctor.name}>
                                {defaultDoctor.name}
                            </option>
                            {doctors
                                .filter((doctor) => doctor.room === selectedRoom)
                                .sort(function (a, b) {
                                    if (a.details === b.details) {
                                        return a.name.localeCompare(b.name);
                                    } else return a.details.localeCompare(b.details);
                                })
                                .map((item, index) => (
                                    <option key={index + 1} value={item.name}>
                                        {item.name}
                                        {item.details ? " - " + item.details : ""}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="form-date" className="form-label">
                            Tanggal
                        </label>
                        <DatePicker
                            className="form-control"
                            selected={selectedDate}
                            onChange={(date) => onChangeDate(new Date(date))}
                            locale="id"
                            dateFormat="dd-MM-yyyy"
                            minDate={today}
                            maxDate={maxDay}
                        />
                    </div>
                    {guarantor === "Umum" && (
                        <div className="mb-3">
                            <label className="form-label" htmlFor="listpaymentmethods">
                                Metode Pembayaran
                            </label>
                            <select required className="form-control" id="listpaymentmethods" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                                <option value="">Pilih Metode Pembayaran</option>
                                {paymentMethods.map((paymentMethod, index) => (
                                    <option key={index} value={paymentMethod.name}>
                                        {paymentMethod.description}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {selectedDoctor && selectedDate && (
                        <>
                            <div className="mb-3">Jadwal hari: {moment(selectedDate).format("dddd, DD-MM-YYYY")}</div>
                            {getScheduledTimes().length === 0 ? (
                                <>
                                    <div className="mb-3">Tidak ada jadwal praktek</div>
                                </>
                            ) : (
                                <>
                                    <div className="mb-3">
                                        {getScheduledTimes().map((item, index) => (
                                            <div key={index} className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={"radio"}
                                                    id={"inlineRadio" + index}
                                                    checked={item === selectedTime ? "checked" : ""}
                                                    onChange={(e) => setSelectedTime(e.target.value)}
                                                    value={item}
                                                    required
                                                    disabled={isSelectedSlotDisabled(item)}
                                                />
                                                <label className="form-check-label" htmlFor={"inlineRadio" + index}>
                                                    {item} {isSelectedSlotDisabled(item) ? "(FULL)" : ""}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mb-3 btn-group">
                                        <button type="submit" className={"btn btn-outline-primary"}>
                                            Lanjut
                                        </button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </form>
            )}

            <br></br>
            <h6>
                <b>Syarat & Ketentuan Pendaftaran Online</b>
            </h6>
            <ul>
                <li>Pendaftaran online bertujuan untuk mempercepat proses pendafaran pasien di Rumah Sakit</li>
                <li>
                    Untuk instruksi cara bayar, silahkan membuka halaman{" "}
                    <a href="https://www.rscitrasundari.com/carabayar.html" target="_blank" rel="noreferrer">
                        berikut
                    </a>
                </li>
                <li>Nomor antrian tidak diberikan setelah pendafaran terkonfirmasi</li>
                <li>
                    Urutan pelayanan di poliklinik berdasarkan pada waktu kedatangan pasien di meja <i>Nurse Station</i>
                </li>
                <li>
                    Jadwal praktek dokter <b>dapat berubah sewaktu-waktu</b>
                </li>
                <li>Untuk informasi lebih lanjut, silahkan menghubungi Customer Care via WhatsApp di (0254) 396710</li>
            </ul>
        </div>
    );
}

/*

                    <table className="table table-sm">
                        <tbody>
                            {allRegistrations
                                .filter((reg) => reg.doctor === selectedDoctor.name && new Date(reg.date).getTime() === selectedDate.getTime() && reg.time === selectedTime)
                                .map((reg) => (
                                    <tr key={reg._id}>
                                        <td>{reg.patient.mrn}</td>
                                        <td>{reg.patient.fullName}</td>
                                        <td>{reg.doctor}</td>
                                        <td>{reg.date}</td>
                                        <td>{moment(new Date(reg.date)).format("DD-MM-YYYY HH:mm")}</td>
                                        <td>{reg.time}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    
{guarantor === "Asuransi" && (
                            <>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="form-company">
                                        Perusahaan
                                    </label>
                                    <AutocompleteFlex items={companies} handleChange={onChangeCompany} handleClick={onChangeCompany} placeholder={company || "Nama perusahaan"} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="image">Upload Kartu Asuransi Baru</label>
                                    <input ref={inputFile} type="file" id="image" name="image" required accept=".png,.jpg,.jpeg" />
                                </div>
                                {loadedPatient && loadedPatient.insuranceCardImg && (
                                    <div className="mb-3">
                                        <label htmlFor="uploadedImg">Kartu Asuransi yang terdaftar</label>
                                        <br></br>
                                        <img
                                            name="uploadedImg"
                                            height={150}
                                            alt="Kartu Asuransi"
                                            src={window.URL.createObjectURL(new Blob([Int8Array.from(loadedPatient.insuranceCardImg.data.data)], { type: loadedPatient.insuranceCardImg.contentType }))}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        */
