import React, { useContext } from "react";
//import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./component/auth/login.component";
import Main from "./component/register/main.component";
import PatientContext from "./context/patient.context";
import Navbar from "./component/common/navbar.component";
import ProtectedRoute from "./ProtectedRoute";

export default function Router() {
    const { patient } = useContext(PatientContext);

    return (
        <BrowserRouter>
            {patient === undefined ? (
                <div className="d-flex align-items-center mt-3 ml-3 mr-3">
                    <strong>Loading...</strong>
                    <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                </div>
            ) : (
                <div className="container">
                    <Navbar />
                    <Switch>
                        <Route path="/login" component={Login} />
                        <ProtectedRoute path="/" exact component={Main} />
                        <ProtectedRoute path="/register" component={Main} />
                    </Switch>
                </div>
            )}
        </BrowserRouter>
    );
}
