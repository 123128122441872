import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import domain from "../util/domain";

const PatientContext = createContext();

function PatientContextProvider(props) {
    const [patient, setPatient] = useState(undefined);

    async function getPatient() {
        const patientRes = await axios.get(`${domain}/patients/loggedIn`);
        setPatient(patientRes.data);
    }

    useEffect(() => {
        getPatient();
    }, []);

    return <PatientContext.Provider value={{ patient, getPatient }}>{props.children}</PatientContext.Provider>;
}

export default PatientContext;
export { PatientContextProvider };
